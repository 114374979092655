import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/inicio",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    redirect: "/inicio",
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () => import("../views/UsuariosView.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "users",
    }
  },
  {
    path: "/clientes",
    name: "clientes",
    component: () => import("../views/ClientesView.vue"),
    meta:{
      requiresAuth: true,
      requiresPermission: "clients"
    }
  },
  {
    path: "/habitaciones",
    name: "habitaciones",
    component: () => import("../views/HabitacionView.vue"),
    meta:{
      requiresAuth: true,
      requiresPermission: "rooms"
    }
  },
  {
    path: "/cuenta",
    name: "cuenta",
    component: () => import("../views/CuentaView.vue"),
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/distribution",
    name: "distribution",
    component: () => import("../views/DistributionView.vue"),
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/lavanderia",
    name: "lavanderia",
    component: () => import("../views/LavanderiaView.vue"),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/PersonalView.vue"),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/alimentacion",
    name: "alimentacion",
    component: () => import("../views/AlimentacionView.vue"),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/proveedor",
    name: "proveedor",
    component: () => import("../views/ProvView.vue"),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/aseo",
    name: "aseo",
    component: () => import("../views/AseoView.vue"),
    meta:{
      requiresAuth: true
    }
  },
  //Rutas sin autenticacion
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.token) {
      await store.dispatch("setRefreshToken");
    }
    if (store.state.token) {
      if (store.state.user.cambio_clave) {
        if (to.name === "cuenta") {
          next();
        } else {
          next({ name: "cuenta" });
        }
      } else {
        if (to.matched.some((record) => record.meta.requiresPermission)) {
          if (
            store.state.user.permissions.includes(to.meta.requiresPermission)
          ) {
            next();
          } else {
            next({ name: "home" });
          }
        } else {
          next();
        }
      }
    } else {
      next({ name: "login" });
    }
  } else {
    if (store.state.token) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;