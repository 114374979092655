<template>
  <div>
    <v-app-bar color="primary" flat density="compact" border="0">
        <template v-slot:prepend>
          <v-btn rounded="0" icon @click.stop="switchRail()">
            <v-tooltip
              activator="parent"
              location="bottom"
              :text="rail ? 'Expandir Menú' : 'Cerrar Menú'"
            />
            <v-icon>{{ rail ? "mdi-menu" : "mdi-menu-open" }}</v-icon>
          </v-btn>
          <v-btn rounded="0" icon @click="openWebUrl">
            <v-tooltip activator="parent" location="bottom" text="Sitio Web" />
            <v-icon>mdi-web</v-icon>
          </v-btn>
          <v-btn rounded="0" icon @click="abrirMapa=true">
            <v-tooltip activator="parent" location="bottom" text="Mapa Hostal" />
            <v-icon>mdi-map-outline</v-icon>
          </v-btn>
          <v-btn rounded="0" icon @click="abrirPersonal=true">
            <v-badge v-if="fechasPersonal && (fechasPersonal.lista1.length || 
            fechasPersonal.lista2.length || fechasPersonal.lista3.length)"  color="red" dot/>
            <v-tooltip activator="parent" location="bottom" text="Personal" />
            <v-icon>mdi-badge-account-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:append>
          <div class="form-check mr-2">
            <label class="form-check-label mr-1" for="exampleRadios1">Turno AB</label>
            <input v-if="turno==='AB'" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
            <input v-else class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" @click="cambiarTurno()">
          </div>
          <div class="form-check mr-3">
                <label class="form-check-label" for="exampleRadios1"> CD </label>
                <input v-if="turno==='CD'" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked>
                <input v-else class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" @click="cambiarTurno()">
          </div>
          <v-btn rounded="0" icon :to="{ path: 'cuenta'}" id="cambio-clave">
            <v-icon>mdi-account-lock</v-icon>
          </v-btn>
          <v-tooltip
            activator="#cambio-clave"
            location="bottom"
            text="Cambio de Clave"
          />
          <v-btn rounded="0" icon class="no-click">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>

          {{ user.name + " " + user.lastname }}

        </template>
      </v-app-bar>
      <v-dialog
        v-model="abrirMapa"
        width="950"
      >
        <v-card>
          <div :style="{ backgroundImage: 'url(' + require('@/assets/navbar/mapa_final.png') + ')', height: '500px' }">
            <v-btn color="error" variant="flat" style="position: absolute; bottom: 10px; left: 10px;" @click="abrirMapa=false">
              Salir
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <dialogo-principal
        v-if="abrirPersonal"
        :dialog="abrirPersonal"
        :fechasPersonal="fechasPersonal"
        @cerrar="cerrarDialogoEdicion"
      />
    </div>
  </template>
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import DialogoPrincipal from "../personal/principalDialog.vue";
  import personal from "@/services/personal";

  export default {
    data() {
      return {
        abrirMapa: false,
        abrirPersonal: false,
        fechasPersonal: null, 
      };
    },
    emits: ["emitsPrincipal"],
    components:{
      DialogoPrincipal
    },
    computed: {
      ...mapState(["rail", "user", "turno"]),
    },
    methods: {
      ...mapActions(["switchRail"]),
      ...mapMutations(['SET_TURNO']),
      cambiarTurno(){
        if(this.turno == 'AB'){
          this.SET_TURNO('CD');
        }else{
          this.SET_TURNO('AB');
        }
      },
      cerrarDialogoEdicion() {
        this.abrirPersonal = false;
      },
      openWebUrl() {
        const webUrl = "http://wayraspa.com"
        if (webUrl) {
          window.open(webUrl, '_blank');
        }
      },
      async cargarFechasPersonal() { 
        try {
          const response = await personal.obtenerPersonalFechas();
          this.fechasPersonal = response.data;
          console.log("Fechas del personal:", this.fechasPersonal);
        } catch (error) {
          console.error("Error al obtener fechas del personal:", error);
        }
      }
    },
    mounted() {
      this.cargarFechasPersonal();
    }
  };
</script>
