<template>
    <v-dialog
        :model-value="dialog"
        width="800"
        @update:model-value="cerrar()"
        id="dialogoEdicion"
    >
        <v-card>
            <v-card-text>
                <v-tabs v-model="tab" bg-color="black" color="white" grow>
                    <v-tab v-for="item in items" :key="item" :value="item">
                        <v-icon :icon="item.icon">
                        </v-icon>
                        <span class="ml-3">{{ item.title }}</span>
                    </v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item v-for="item in items" :key="item" :value="item">
                        <div v-if="item.title === 'Cumpleaños'">
                            <el-table
                                border 
                                :data="fechasPersonal.lista1"
                                row-key="_id"
                                style="width: 100%"
                                :row-class-name="tableRowClassName"
                                :cell-class-name="classChecker"
                            >
                                <template #empty>
                                    <el-empty
                                        class="pt-6"
                                        :image="require('@/assets/no-data/no-file.png')"
                                        :image-size="100"
                                        description="No se encontraron personas"
                                    />
                                </template>
                                <el-table-column label="Nombre" prop="name"></el-table-column>
                                <el-table-column label="Apellido Paterno" prop="f_lastname"></el-table-column>
                                <el-table-column label="Fecha" prop="fecha_cumple">
                                    <template #default="scope">
                                        {{ scope.row.fecha_Nac ? conv.formatFecha(scope.row.fecha_Nac) : "Sin fecha"}}
                                    </template>
                                </el-table-column>
                            </el-table>   
                        </div>
                        <div v-else-if="item.title === 'Fin Contrato'">
                            <el-table
                                border 
                                :data="fechasPersonal.lista2"
                                row-key="_id"
                                style="width: 100%"
                                :row-class-name="tableRowClassName"
                                :cell-class-name="classChecker"
                            >
                                <template #empty>
                                    <el-empty
                                        class="pt-6"
                                        :image="require('@/assets/no-data/no-file.png')"
                                        :image-size="100"
                                        description="No se encontraron personas"
                                    />
                                </template>
                                <el-table-column label="Nombre" prop="name"></el-table-column>
                                <el-table-column label="Apellido Paterno" prop="f_lastname"></el-table-column>
                                <el-table-column label="Fecha" prop="fin_contrato">
                                    <template #default="scope">
                                        {{ scope.row.fecha_Final ? conv.formatFecha(scope.row.fecha_Final) : "Sin fecha"}}
                                    </template>
                                </el-table-column>
                            </el-table>   
                        </div>
                        <div v-else-if="item.title === 'Vencimiento Carnet'">
                            <el-table
                                border 
                                :data="fechasPersonal.lista3"
                                row-key="_id"
                                style="width: 100%"
                                :row-class-name="tableRowClassName"
                                :cell-class-name="classChecker"
                            >
                                <template #empty>
                                    <el-empty
                                        class="pt-6"
                                        :image="require('@/assets/no-data/no-file.png')"
                                        :image-size="100"
                                        description="No se encontraron personas"
                                    />
                                </template>
                                <el-table-column label="Nombre" prop="name"></el-table-column>
                                <el-table-column label="Apellido Paterno" prop="f_lastname"></el-table-column>
                                <el-table-column label="Fecha" prop="fecha_carnet">
                                    <template #default="scope">
                                        {{ scope.row.fecha_Carnet ? conv.formatFecha(scope.row.fecha_Carnet) : "Sin fecha"}}
                                    </template>
                                </el-table-column>
                            </el-table>           
                        </div>
                    </v-window-item>
                </v-window>      
            </v-card-text>
            <v-card-actions class="pa-4">
                <v-btn color="error" variant="outlined" elevation="2" @click="cerrar()">
                    Salir
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import conversores from "@/helpers/conversores";

export default{
    name: "DialogoEdicion",
    emits: ["cerrar"],
    props:{
        dialog: Boolean,
        fechasPersonal: {
            type: Object, 
            default: () => ({ lista1: [], lista2: [], lista3: [] }) 
        }
    },
    computed:{
        conv(){
            return conversores;
        }
    },
    data(){
        return{
            tab: 'Cumpleaños',
            items:[
                {"title": "Cumpleaños", "icon": "mdi-cake-variant"},
                {"title": "Fin Contrato", "icon": "mdi-text-box-remove"},
                {"title": "Vencimiento Carnet", "icon": "mdi-card-account-details-outline"}
            ],
            loading: true,
        }
    },
    methods:{
        cerrar(){
            this.$emit("cerrar");
        },
        tableRowClassName(data) {
            if (data.rowIndex % 2 == 1) {
                return "striped-class";
            } else return "";
        },
        classChecker({column}){
            if(column.property == 'fin_contrato' || column.property == 'fin_cumple' || column.property == 'fin_carnet'){
                return 'redClass';
            }
        }
    },
}

</script>

<style scoped>
.textName {
  font-weight: 400;
  font-size: 20px;
}
.align-right {
    margin-left: 15px;  
}

</style>