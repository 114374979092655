import moment from "moment";
import ChileanRutify from 'chilean-rutify';

export default {
  formatMonto(monto, currency) {
    let intMonto = parseInt(parseFloat(monto));
    if (currency)
      return Intl.NumberFormat("es-CL", {
        currency: "CLP",
        style: "currency",
      }).format(Math.abs(intMonto));
    else
      return Intl.NumberFormat("es-CL", { currency: "CLP" }).format(
        Math.abs(intMonto)
      );
  },
  formatRut(rut){
    rut = ChileanRutify.formatRut(rut);
    return rut;
  },
  formatPorcentaje(valor) {
    valor = valor.replace(/,/g, ".");
    let num = parseFloat(valor);
    return num.toFixed(3) + "%";
  },
  formatPorcentaje2(valor) {
    let num = parseFloat(valor) * 100;
    return num.toFixed(2) + "%";
  },
  formatDias(valor) {
    return valor === "1" ? valor + " día" : valor + " días";
  },
  formatFecha(fecha) {
    return moment.utc(new Date(fecha)).format("DD/MM/YYYY");
  },
  formatFechaHora(fecha) {
    return moment(new Date(fecha)).format("DD/MM/YYYY HH:mm");
  },
  formatFecha2(fecha, srcFormat) {
    return moment(fecha, srcFormat).format("DD/MM/YYYY");
  },
  ajustarZonaHoraria(fecha) {
      return moment(fecha).subtract(1, 'days').toDate();
  },
  insertarHoraHoy(fecha){
      return moment(fecha).calendar();
  },
  formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  fechaNombreDocs() {
    return moment(new Date(Date.now())).format("DDMMYYHHmmss");
  },
  formatFechaHora2(fecha, srcFormat) {
    return moment(fecha, srcFormat).format("DD/MM/YYYY HH:mm");
  },
  capitalizeString(texto) {
    let arr = texto.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    return arr.join(" ");
  },
  capitalizeFirtsString(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  },
  desmonetizar(monto) {
    return monto.replace("$", "").split(".").join("");
  },
  
};